<template>
  <v-card color="surface" height="100%" class="rounded-lg">
    <div class="text-h5 px-6 py-2 mb-4 font-weight-black">
      Jogadores por liga
    </div>

    <div id="chart">
      <apexchart
        type="polarArea"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data() {
    return {
      series: [14, 23, 21, 17, 15],
      chartOptions: {
        chart: {
          type: "polarArea",
          toolbar: {
            show: false,
          },
        },
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
      },
    };
  },

  components: {
    apexchart: VueApexCharts,
  },
};
</script>

<style></style>
