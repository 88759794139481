<template>
  <v-card color="surface" height="100%" class="rounded-lg">
    <div class="text-h5 px-6 py-2 mb-4 font-weight-black">Equipes por liga</div>

    <div id="chart">
      <apexchart
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data() {
    return {
      series: [
        {
          data: [
            {
              x: "Liga 1",
              y: 10,
            },
            {
              x: "Liga 2",
              y: 18,
            },
            {
              x: "Liga 3",
              y: 13,
            },
            {
              x: "Liga 4",
              y: 13,
            },
            {
              x: "Liga 5",
              y: 13,
            },
          ],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
      },
    };
  },

  components: {
    apexchart: VueApexCharts,
  },
};
</script>

<style></style>
