<template>
  <v-container>
    <header-view :title="'Dashboard'" class="mb-8" />

    <loader-content v-if="loading" />

    <section v-else>
      <v-row>
        <v-col
          v-for="(item, i) in analytics"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          :key="i"
        >
          <v-card color="surface" class="rounded-lg">
            <v-list-item>
              <v-list-item-avatar :color="item.color" rounded>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ item.label }}
                </v-list-item-subtitle>
                <v-list-item-title class="text-h6">
                  {{ item.value }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="7">
          <TeamsLeagueChart />
        </v-col>

        <v-col cols="5">
          <PlayersLeagueChart />
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import TeamsLeagueChart from "@/components/league/dashboard/TeamsLeagueChart.vue";
import PlayersLeagueChart from "@/components/league/dashboard/PlayersLeagueChart.vue";
import { getDashboard } from "@/services/league/dashboard.js";

export default {
  data() {
    return {
      loading: true,
      dashboard: null,
    };
  },

  components: {
    TeamsLeagueChart,
    PlayersLeagueChart,
  },

  computed: {
    analytics() {
      if (!this.dashboard) return [];

      return [
        {
          color: "green",
          icon: "mdi-account",
          label: "Quantidade de jogadores",
          value: this.dashboard.playersRegistered,
        },
        {
          color: "purple",
          icon: "mdi-controller-classic",
          label: "Quantidade de jogos",
          value: this.dashboard.gamesCount,
        },
        {
          color: "orange",
          icon: "mdi-trophy",
          label: "Quantidade de ligas",
          value: this.dashboard.leaguesCount,
        },
        {
          color: "teal",
          icon: "mdi-shield-sword",
          label: "Quantidade de Equipes",
          value: this.dashboard.teamsCount,
        },
        {
          color: "red",
          icon: "mdi-account-plus",
          label: "Jogadores registrados hoje",
          value: this.dashboard.registeredToday,
        },
        {
          color: "light-blue",
          icon: "mdi-account-multiple-plus",
          label: "Equipes criadas hoje",
          value: this.dashboard.teamsRegisteredToday,
        },
      ];
    },
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        await getDashboard().then((res) => {
          this.dashboard = res;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
